import React, {ReactNode} from 'react';
import {Layout as AntLayout} from 'antd';
import Footer from './Footer';
import Sidebar from '../navigation/Sidebar';
import './layout.sass';

interface LayoutProps
{
	children?: ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = (props) =>
{
	return (
		<AntLayout
			className={'layout-1678'}
		>
			<Sidebar />
			<AntLayout>
				<AntLayout.Content className={'content-BEB7'}>
					{props.children}
				</AntLayout.Content>
				<Footer />
			</AntLayout>
		</AntLayout>
	);
};

export default Layout;