import React, {memo} from 'react';
import CopyPopover from '../kit/clipboard/CopyPopover';
import version from '../../resources/version.json';
import {useHistory} from 'react-router-dom';
import './footer.sass';

const Footer = () =>
{
	const history = useHistory();

	const onClickVersion = () =>
	{
		history.push('/version');
	};

	return (
		<div className={'footer-8A0D'}>
			<div className={'fade-27F8'}>

			</div>
			<div className={'content-9B01'}>
				<div className={'text-D3F7'}>
					<div>
						Need help?
					</div>
					<div>
						Contact <CopyPopover text={'Knuddel.7928'} />
					</div>
				</div>

				<div className={'text-D3F7'}>
					<div
						className={'version-C541'}
						onClick={onClickVersion}
					>
						v{version.version}
					</div>
					<div>
						Made with pure hatred.
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Footer);