import React from 'react';
import './notFoundPage.sass';
import {useHistory} from "react-router-dom"

const NotFoundPage = () =>
{

	const history = useHistory()

	const onClick = () =>
	{
		history.goBack()
	};

	return (
		<div className={'pages notFound notFoundPage'}>
			<div className={'title'}>
				404
			</div>
			<div className={'description'}>
				Page not found
			</div>
			<div className={'button'} onClick={onClick}>
				Do you want to go back?
				<div className={'underline'}>

				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;