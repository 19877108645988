import React from 'react';
import {Build} from '../../../client/Build';
import {Card} from 'antd';
import BuildContent from './BuildContent';

interface BuildCardProps
{
	build: Build;
}

const BuildCard: React.FunctionComponent<BuildCardProps> = (props) =>
{
	return (
		<Card>
			<BuildContent build={props.build}/>
		</Card>
	);
};

export default BuildCard;