import React, {memo, useState} from 'react';
import {Menu} from 'antd';
import Sider from 'antd/es/layout/Sider';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
	HomeOutlined,
	DatabaseOutlined,
	SettingOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import './sidebar.sass';

const Sidebar = () =>
{
	const [collapsed, setCollapsed] = useState(true);
	const history = useHistory();
	const match = useRouteMatch<{ firstLevel: string | undefined }>(['/:firstLevel']);

	const firstLevel: string = (() =>
	{
		if (!match?.params.firstLevel)
		{
			return '/';
		}
		return match.params.firstLevel.toLowerCase();
	})();

	const onClick = (e: any) =>
	{
		history.push(e.key);
	};

	const version = (() =>
	{
		if (firstLevel !== 'version')
		{
			return null;
		}
		return (
			<Menu.Item
				key={'version'}
				icon={<FileTextOutlined />}
			>
				Version Changelog
			</Menu.Item>
		);
	})();

	return (
		<>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={setCollapsed}
			>
			</Sider>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={setCollapsed}
				className={'sidebar-EF0D'}
			>
				<div className='logo' />
				<Menu
					theme='dark'
					selectedKeys={[firstLevel]}
					mode='inline'
					onClick={onClick}
					className={'menu-FFDC'}
				>
					<Menu.ItemGroup>
						<Menu.Item
							key={'/'}
							icon={<HomeOutlined />}
						>
							Home
						</Menu.Item>
						<Menu.Divider />

						<Menu.Item
							key={'items'}
							icon={<DatabaseOutlined />}
						>
							Item Database
						</Menu.Item>
					</Menu.ItemGroup>
					<Menu.ItemGroup>
						{version}
						<Menu.Item
							key={'settings'}
							icon={<SettingOutlined />}
						>
							Settings
						</Menu.Item>
					</Menu.ItemGroup>
				</Menu>
			</Sider>
		</>
	);
};

export default memo(Sidebar);