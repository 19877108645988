import React, {useState} from 'react';
import {Popconfirm} from 'antd';
import {Spec} from '../../../resources/specs/specs';
import specsMap from '../../../resources/specs/specsMap';
import SpecSelect from './SpecSelect';
import "./specIcon.sass" // -77C4 -662A -EC97 -4851

interface SpecIconProps
{
	spec: Spec,
	onChange: (value: Spec) => void;
}

const SpecIcon: React.FunctionComponent<SpecIconProps> = (props) =>
{
	const [selected, setSelected] = useState<Spec>(props.spec);
	const spec = specsMap[props.spec];

	const onConfirm = () =>
	{
		props.onChange(selected);
	};

	const onCancel = () =>
	{
		setSelected(props.spec);
	};

	const popOver = (
		<div>
			<SpecSelect
				value={selected}
				onChange={setSelected}
			/>
		</div>
	);

	return (
		<Popconfirm
			title={popOver}
			icon={null}
			overlayClassName={'popconfirm-FC35'}
			onCancel={onCancel}
			onConfirm={onConfirm}
		>
			<img
				className={"icon-06A0"}
				src={spec.icon}
				alt={''}
			/>
		</Popconfirm>
	);
};

export default SpecIcon;