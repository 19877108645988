import React from 'react';
import {Button, Card, Form, Input} from 'antd';
import './versionPage.sass';
import version from '../../../resources/version.json';
import changelog from '../../../resources/changelog.json';
import {ArrowUpOutlined} from '@ant-design/icons';

const VersionPage = () =>
{
	const onClickCommit = () =>
	{
		window.open(`https://github.com/kaibarzen/tcg/commit/${version.commit}`);
	};

	return (
		<div className={'pages version versionPage'}>
			<Card
				bordered={false}
				title={'Changelog'}
			>
				<div dangerouslySetInnerHTML={{__html: changelog.changelog}}>

				</div>
			</Card>
			<Card
				bordered={false}
				title={'Meta'}
			>
				<Form
					labelCol={{span: 8}}
					wrapperCol={{span: 16}}
					layout={"horizontal"}
				>
					<Form.Item
						label={'Version'}
					>
						<Input
							disabled={true}
							value={version.version}
						/>
					</Form.Item>

					<Form.Item
						label={'Actor'}
					>
						<Input
							disabled={true}
							value={version.actor}
						/>
					</Form.Item>
					<Form.Item
						label={'Branch'}
					>
						<Input
							disabled={true}
							value={version.branch}
						/>
					</Form.Item>

					<Form.Item
						label={'Build Commit'}
					>
						<div className={'commit'}>
							<Input
								disabled={true}
								value={version.commit}
							/>
							<Button
								className={'button'}
								onClick={onClickCommit}
							>
								<ArrowUpOutlined rotate={45} />
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
};

export default VersionPage;