export enum Guardian
{
	Core = 'GUARDIAN_CORE',
	Dragonhunter = 'GUARDIAN_DRAGONHUNTER',
	Firebrand = 'GUARDIAN_FIREBRAND',
	Willbender = 'GUARDIAN_WILLBENDER',
}

export enum Revenant
{
	Core = 'REVENANT_CORE',
	Herald = 'REVENANT_HERLAD',
	Renegade = 'REVENANT_RENEGADE',
	Vindicator = 'REVENANT_VINDICATOR'
}

export enum Warrior
{
	Core = 'WARRIOR_CORE',
	Berserker = 'WARRIOR_BERSERKER',
	Spellbreaker = 'WARRIOR_SPELLBREAKER',
	Bladesworn = 'WARRIOR_BLADESWORN'
}

export enum Engineer
{
	Core = 'ENGINEER_CORE',
	Scrapper = 'ENGINEER_SCRAPPER',
	Holosmith = 'ENGINEER_HOLOSMITH',
	Mechanist = 'ENGINEER_MECHANIST'
}

export enum Ranger
{
	Core = 'RANGER_CORE',
	Druid = 'RANGER_DRUID',
	Soulbeast = 'RANGER_SOULBEAST',
	Untamed = 'RANGER_UNTAMED'
}

export enum Thief
{
	Core = 'THIEF_CORE',
	Daredevil = 'THIEF_DAREDEVIL',
	Deadeye = 'THIEF_DEADEYE',
	Specter = 'THIEF_SPECTER'
}

export enum Elementalist
{
	Core = 'ELEMENTALIST_CORE',
	Tempest = 'ELEMENTALIST_TEMPEST',
	Weaver = 'ELEMENTALIST_WEAVER',
	Catalyst = 'ELEMENTALIST_CATALYST'
}

export enum Mesmer
{
	Core = 'MESMER_CORE',
	Chronomancer = 'MESMER_CHRONOMANCER',
	Mirage = 'MESMER_MIRAGE',
	Virtuoso = 'MESMER_VIRTUOSO'
}

export enum Necromancer
{
	Core = 'NECROMANCER_CORE',
	Reaper = 'NECROMANCER_REAPER',
	Scourge = 'NECROMANCER_SCOURGE',
	Harbinger = 'NECROMANCER_HARBINGER'
}

export enum GenericSpec
{
	Quaggan = 'GENERIC_QUAGGAN',
	PinkQuaggan = 'GENERIC_PINK_QUAGGAN',
	DPS = 'GENERIC_DPS'
}

export type Spec =
	Guardian
	| Revenant
	| Warrior
	| Engineer
	| Ranger
	| Thief
	| Elementalist
	| Mesmer
	| Necromancer
	| GenericSpec;