import React from 'react';
import {
	Elementalist,
	Engineer,
	GenericSpec,
	Guardian, Mesmer, Necromancer,
	Ranger,
	Revenant, Spec,
	Thief,
	Warrior,
} from '../../../resources/specs/specs';
import specsMap from '../../../resources/specs/specsMap';
import './specSelect.sass';

interface SpecSelectProps
{
	value: Spec,
	onChange: (value: Spec) => void
}

const map = [GenericSpec, Guardian, Revenant, Warrior, Engineer, Ranger, Thief, Elementalist, Mesmer, Necromancer];

const SpecSelect: React.FunctionComponent<SpecSelectProps> = (props) =>
{

	const spec = specsMap[props.value];

	const singleIcon = (specKey: Spec, i: number) =>
	{
		const spec = specsMap[specKey as Spec];

		const onClick = () =>
		{
			props.onChange(specKey);
		};

		if (specKey === props.value)
		{
			return (
				<div
					className={'icon-8E3E'}
					style={{
						filter: `drop-shadow(0 0 0.4em ${spec.color})`,
					}}
				>
					<img
						src={spec.icon}
						alt={''}
					/>
				</div>
			);
		}

		return (
			<div
				className={'icon-8E3E unselected-B125'}
				onClick={onClick}
			>
				<img
					src={spec.icon}
					alt={''}
				/>
			</div>
		);
	};

	const singleWord = (word: string) =>
	{
		return <div>
			{word.split('').map((char) =>
			{
				return <div className={'col-2354'}>{char}</div>;
			})}
		</div>;
	};

	return (
		<div className={'popover-F3BC'}>
			<div
				style={{color: spec.color}}
				className={'title-AA5C'}
			>
				{
					spec.display.split(' ').map(singleWord)
				}
			</div>
			<div>
				{
					map.map((item, i) =>
					{
						return <div className={'row-4C02'}> {
							Object.values(item).map(singleIcon)
						}</div>;
					})
				}
			</div>
		</div>
	);
};

export default SpecSelect;