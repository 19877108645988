import React from 'react';
import {Build, State} from '../../../client/Build';
import {DownloadOutlined, LinkOutlined, LockOutlined, UnlockOutlined} from '@ant-design/icons';
import './buildState.sass';
import {Popconfirm} from 'antd';
import {observer} from 'mobx-react-lite'; // -FAF3 -9B41 -529D -4DA4

interface BuildStateProps
{
	build: Build;
}

const BuildState: React.FunctionComponent<BuildStateProps> = (props) =>
{
	const onUnlock = () =>
	{
		props.build.state = State.Unlocked;
	};

	const onLock = () =>
	{
		props.build.state = State.Locked;
	};

	const message = (
		<div>
			Unlink and import this build.
		</div>
	)

	switch (props.build.state)
	{
		case State.Unlocked:
			return (
				<div
					className={'container-4DA4'}
					onClick={onLock}
				>
					<UnlockOutlined className={'icon-CC65'} />
				</div>
			);
		case State.Locked:
			return (
				<div
					className={'container-4DA4'}
					onClick={onUnlock}
				>
					<LockOutlined className={'icon-CC65'} />
				</div>
			);
		case State.Linked:
			return (
				<Popconfirm
					title={message}
					onConfirm={onUnlock}
					okText={"Confirm"}
					cancelText={"Cancel"}
					icon={<DownloadOutlined />}
				>
					<div className={'container-4DA4'}>
						<LinkOutlined className={'icon-CC65'} />
					</div>
				</Popconfirm>
			);
		default:
			return (
				<div
					className={'container-4DA4'}
					onClick={onUnlock}
				>
					<LockOutlined className={'icon-CC65'} />
				</div>
			);
	}
};

export default observer(BuildState);