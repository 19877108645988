import React, {ReactNode} from 'react';
import {Popover} from 'antd';
import Copy from './Copy';
import './copyPopover.sass';

/**
 * @property text Text to copy into clipboard
 * @property preview Display an preview of the copied text
 * @property children
 */
interface Props
{
	text: string,
	preview?: boolean,
	children?: ReactNode
}

/**
 * Wrap on popover to copy text around a node
 * @param props
 * @constructor
 */
const CopyPopover = (props: Props) =>
{
	return (
		<Popover
			content={
				<Copy
					preview={props.preview}
					text={props.text}
				/>
			}
			trigger={'hover'}
		>
			<span className={'text-38AE'}>
				{props.children || props.text}
			</span>
		</Popover>
	);
};

export default CopyPopover;