import {Spec} from './specs';

import GUARDIAN_CORE from './img/GUARDIAN_CORE.png';
import GUARDIAN_DRAGONHUNTER from './img/GUARDIAN_DRAGONHUNTER.png';
import GUARDIAN_FIREBRAND from './img/GUARDIAN_FIREBRAND.png';
import GUARDIAN_WILLBENDER from './img/GUARDIAN_WILLBENDER.png';

import REVENANT_CORE from './img/REVENANT_CORE.png';
import REVENANT_HERALD from './img/REVENANT_HERALD.png';
import REVENANT_RENEGADE from './img/REVENANT_RENEGADE.png';
import REVENANT_VINDICATOR from './img/REVENANT_VINDICATOR.png';

import WARRIOR_CORE from './img/WARRIOR_CORE.png';
import WARRIOR_BERSERKER from './img/WARRIOR_BERSERKER.png';
import WARRIOR_SPELLBREAKER from './img/WARRIOR_SPELLBREAKER.png';
import WARRIOR_BLADESWORN from './img/WARRIOR_BLADESWORN.png';

import ENGINEER_CORE from './img/ENGINEER_CORE.png';
import ENGINEER_SCRAPPER from './img/ENGINEER_SCRAPPER.png';
import ENGINEER_HOLOSMITH from './img/ENGINEER_HOLOSMITH.png';
import ENGINEER_MECHANIST from './img/ENGINEER_MECHANIST.png';

import RANGER_CORE from './img/RANGER_CORE.png';
import RANGER_DRUID from './img/RANGER_DRUID.png';
import RANGER_SOULBEAST from './img/RANGER_SOULBEAST.png';
import RANGER_UNTAMED from './img/RANGER_UNTAMED.png';

import THIEF_CORE from './img/THIEF_CORE.png';
import THIEF_DAREDEVIL from './img/THIEF_DAREDEVIL.png';
import THIEF_DEADEYE from './img/THIEF_DEADEYE.png';
import THIEF_SPECTER from './img/NOT_AVAILABLE.png';

import ELEMENTALIST_CORE from './img/ELEMENTALIST_CORE.png';
import ELEMENTALIST_TEMPEST from './img/ELEMENTALIST_TEMPEST.png';
import ELEMENTALIST_WEAVER from './img/ELEMENTALIST_WEAVER.png';
import ELEMENTALIST_CATALYST from './img/NOT_AVAILABLE.png';

import MESMER_CORE from './img/MESMER_CORE.png';
import MESMER_CHRONOMANCER from './img/MESMER_CHRONOMANCER.png';
import MESMER_MIRAGE from './img/MESMER_MIRAGE.png';
import MESMER_VIRTUOSO from './img/MESMER_VIRTUOSO.png';

import NECROMANCER_CORE from './img/NECROMANCER_CORE.png';
import NECROMANCER_REAPER from './img/NECROMANCER_REAPER.png';
import NECROMANCER_SCOURGE from './img/NECROMANCER_SCOURGE.png';
import NECROMANCER_HARBINGER from './img/NECROMANCER_HARBINGER.png';

import GENERIC_DPS from './img/GENERIC_DPS.png';
import GENERIC_QUAGGAN from './img/GENERIC_QUAGGAN.png';
import GENERIC_PINK_QUAGGAN from './img/GENERIC_PINK_QUAGGAN.png';

export interface SpecItem
{
	display: string,
	icon: string,
	color: string,
}

const map: { [key in Spec]: SpecItem } = {
	GUARDIAN_CORE: {
		display: 'Core Guardian',
		icon: GUARDIAN_CORE,
		color: '#72c1d9',
	},
	GUARDIAN_DRAGONHUNTER: {
		display: 'Dragonhunter',
		icon: GUARDIAN_DRAGONHUNTER,
		color: '#72c1d9',
	},
	GUARDIAN_FIREBRAND: {
		display: 'Firebrand',
		icon: GUARDIAN_FIREBRAND,
		color: '#72c1d9',
	},
	GUARDIAN_WILLBENDER: {
		display: 'Willbender',
		icon: GUARDIAN_WILLBENDER,
		color: '#72c1d9',
	},
	REVENANT_CORE: {
		display: 'Core Revenant',
		icon: REVENANT_CORE,
		color: '#d16e5a',
	},
	REVENANT_HERLAD: {
		display: 'Herald',
		icon: REVENANT_HERALD,
		color: '#d16e5a',
	},
	REVENANT_RENEGADE: {
		display: 'Renegade',
		icon: REVENANT_RENEGADE,
		color: '#d16e5a',
	},
	REVENANT_VINDICATOR: {
		display: 'Vindicator',
		icon: REVENANT_VINDICATOR,
		color: '#d16e5a',
	},
	WARRIOR_CORE: {
		display: 'Core Warrior',
		icon: WARRIOR_CORE,
		color: '#ffd166',
	},
	WARRIOR_BERSERKER: {
		display: 'Berserker',
		icon: WARRIOR_BERSERKER,
		color: '#ffd166',
	},
	WARRIOR_BLADESWORN: {
		display: 'Bladesworn',
		icon: WARRIOR_BLADESWORN,
		color: '#ffd166',
	},
	WARRIOR_SPELLBREAKER: {
		display: 'Spellbreaker',
		icon: WARRIOR_SPELLBREAKER,
		color: '#ffd166',
	},
	ENGINEER_CORE: {
		display: 'Core Engineer',
		icon: ENGINEER_CORE,
		color: '#d09c59',
	},
	ENGINEER_HOLOSMITH: {
		display: 'Holosmith',
		icon: ENGINEER_HOLOSMITH,
		color: '#d09c59',
	},
	ENGINEER_MECHANIST: {
		display: 'Mechanist',
		icon: ENGINEER_MECHANIST,
		color: '#d09c59',
	},
	ENGINEER_SCRAPPER: {
		display: 'Scrapper',
		icon: ENGINEER_SCRAPPER,
		color: '#d09c59',
	},
	RANGER_CORE: {
		display: 'Core Ranger',
		icon: RANGER_CORE,
		color: '#8cdc82',
	},
	RANGER_DRUID: {
		display: 'Druid',
		icon: RANGER_DRUID,
		color: '#8cdc82',
	},
	RANGER_SOULBEAST: {
		display: 'Soulbeast',
		icon: RANGER_SOULBEAST,
		color: '#8cdc82',
	},
	RANGER_UNTAMED: {
		display: 'Untamed',
		icon: RANGER_UNTAMED,
		color: '#8cdc82',
	},
	THIEF_CORE: {
		display: 'Core Thief',
		icon: THIEF_CORE,
		color: '#c08f95',
	},
	THIEF_DAREDEVIL: {
		display: 'Daredevil',
		icon: THIEF_DAREDEVIL,
		color: '#c08f95',
	},
	THIEF_DEADEYE: {
		display: 'Deadeye',
		icon: THIEF_DEADEYE,
		color: '#c08f95',
	},
	THIEF_SPECTER: {
		display: 'Specter',
		icon: THIEF_SPECTER,
		color: '#c08f95',
	},
	ELEMENTALIST_CORE: {
		display: 'Core Elementalist',
		icon: ELEMENTALIST_CORE,
		color: '#f68a87',
	},
	ELEMENTALIST_TEMPEST: {
		display: 'Tempest',
		icon: ELEMENTALIST_TEMPEST,
		color: '#f68a87',
	},
	ELEMENTALIST_WEAVER: {
		display: 'Weaver',
		icon: ELEMENTALIST_WEAVER,
		color: '#f68a87',
	},
	ELEMENTALIST_CATALYST: {
		display: 'Catalyst',
		icon: ELEMENTALIST_CATALYST,
		color: '#f68a87',
	},
	MESMER_CORE: {
		display: 'Core Mesmer',
		icon: MESMER_CORE,
		color: '#b679d5',
	},
	MESMER_CHRONOMANCER: {
		display: 'Chronomancer',
		icon: MESMER_CHRONOMANCER,
		color: '#b679d5',
	},
	MESMER_MIRAGE: {
		display: 'Mirage',
		icon: MESMER_MIRAGE,
		color: '#b679d5',
	},
	MESMER_VIRTUOSO: {
		display: 'Virtuoso',
		icon: MESMER_VIRTUOSO,
		color: '#b679d5',
	},
	NECROMANCER_CORE: {
		display: 'Core Necromancer',
		icon: NECROMANCER_CORE,
		color: '#52a76f',
	},
	NECROMANCER_HARBINGER: {
		display: 'Harbinger',
		icon: NECROMANCER_HARBINGER,
		color: '#52a76f',
	},
	NECROMANCER_REAPER: {
		display: 'Reaper',
		icon: NECROMANCER_REAPER,
		color: '#52a76f',
	},
	NECROMANCER_SCOURGE: {
		display: 'Scourge',
		icon: NECROMANCER_SCOURGE,
		color: '#52a76f',
	},
	GENERIC_DPS: {
		display: 'DPS',
		icon: GENERIC_DPS,
		color: '#FF0000',
	},
	GENERIC_QUAGGAN: {
		display: 'Quaggan',
		icon: GENERIC_QUAGGAN,
		color: '#00BFFF',
	},
	GENERIC_PINK_QUAGGAN: {
		display: 'Pink Quaggan',
		icon: GENERIC_PINK_QUAGGAN,
		color: '#FF1493',
	},
};

export default map;