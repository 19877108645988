import React from 'react';
import {Build} from '../../../client/Build';
import './buildContentHeader.sass';
import specsMap from '../../../resources/specs/specsMap';
import {observer} from 'mobx-react-lite';
import {Typography} from 'antd';
import SpecIcon from '../spec/SpecIcon';
import {Spec} from '../../../resources/specs/specs';
import './buildContentHeader.sass';
import BuildState from './BuildState'; // -7533 -0859 -237E -F8BE

interface BuildContentHeaderProps
{
	build: Build,
}

const BuildContentHeader: React.FunctionComponent<BuildContentHeaderProps> = (props) =>
{
	const spec = specsMap[props.build.spec];

	const onChangeTitle = (title: string) =>
	{
		props.build.title = title;
	};

	const onChangeSpec = (spec: Spec) =>
	{
		props.build.spec = spec;
	};

	return (
		<div className={'row-F0B3'}>
			<div className={'title-588D'}>
				<SpecIcon
					spec={props.build.spec}
					onChange={onChangeSpec}
				/>
				<Typography.Title
					style={{color: spec.color, margin: 0}}
					level={5}
					editable={{onChange: onChangeTitle}}
				>
					{props.build.title}
				</Typography.Title>
			</div>
			<div className={'row-B394'}>
				<div className={'spec-0A30'}>{spec.display}</div>
				<BuildState build={props.build} />
			</div>

		</div>
	);
};

export default observer(BuildContentHeader);