import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.dark.css';
import Layout from './components/layout/Layout';
import {BrowserRouter} from 'react-router-dom';
import ClientContextProvider from './components/hocs/ClientContextProvider';
import ContentRouter from './components/navigation/ContentRouter';

ReactDOM.render(
	<React.StrictMode>
		<ClientContextProvider>
			<BrowserRouter>
				<Layout>
					<ContentRouter />
				</Layout>
			</BrowserRouter>
		</ClientContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);