import React from 'react';
import {Route, Switch} from 'react-router-dom';
import VersionPage from '../pages/version/VersionPage';
import HomePage from '../pages/home/HomePage';
import NotFoundPage from '../pages/notFound/NotFoundPage';

const ContentRouter: React.FunctionComponent = (props) =>
{
	return (
		<Switch>
			<Route path={"/items"}>
				ITEM DB
			</Route>
			<Route path={"/version"} >
				<VersionPage />
			</Route>
			<Route path={"/"} exact={true}>
				<HomePage/>
			</Route>
			<Route>
				<NotFoundPage/>
			</Route>
		</Switch>
	);
};

export default ContentRouter;