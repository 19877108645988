import {makeAutoObservable} from 'mobx';
import {GenericSpec, Spec} from '../resources/specs/specs';

export enum State
{
	Locked = 'LOCKED',
	Unlocked = 'UNLOCKED',
	Linked = 'LINKED'
}

export class Build
{
	get state(): State
	{
		return this._state;
	}

	set state(value: State)
	{
		this.locked = value !== State.Unlocked;
		this._state = value;
	}

	spec: Spec = GenericSpec.PinkQuaggan;
	title: string = 'Example Build';
	private _state: State = State.Linked;
	private locked: boolean = this._state !== State.Unlocked;

	constructor()
	{
		makeAutoObservable(this);
	}
}