import {Card} from 'antd';
import React from 'react';
import './homePage.sass';
import BuildCard from '../../kit/build/BuildCard';
import {Build} from '../../../client/Build';

const build = new Build();

const HomePage = () =>
{
	return (
		<div className={'pages home homePage'}>
			<BuildCard build={build} />

			<Card bordered={false}>
				<BuildCard build={build} />
			</Card>

			<Card bordered={true}>
				<BuildCard build={build} />
			</Card>

			<Card
				bordered={false}
				className={'card'}
			>
				<div className={'text'}>
					Bla Bli BLub
				</div>
			</Card>
		</div>
	);
};

export default HomePage;