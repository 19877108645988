import {CheckOutlined, CopyOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
import './copy.sass';

/**
 * @property text Text that gets copied into the clipboard
 * @property preview Display the text as preview
 */
interface Props
{
	text: string,
	preview?: boolean,
}

/**
 * Copy Icon with user feedback
 * @param props
 * @constructor
 */
const Copy = (props: Props) =>
{
	const [success, setSuccess] = useState(false);

	const onClick = async () =>
	{
		if (success)
		{
			return;
		}
		await navigator.clipboard.writeText(props.text);
		setSuccess(true);

		setTimeout(() =>
		{
			setSuccess(false);
		}, 2000);

	};

	const successNode = (() =>
	{
		if (success)
		{
			return <CheckOutlined className={'success-1020'} />;
		}
		return <CopyOutlined />;
	})();

	const previewNode = (() =>
	{
		if (!props.preview)
		{
			return null;
		}
		if (!success)
		{
			return (
				<div className={'display-454A'}>
					{props.text?.toUpperCase()}
				</div>
			);
		}
		return (
			<>
				<div className={'display-454A success-1020'}>SUCCESS</div>
				<div className={'display-454A hidden-B046'}>
					{props.text?.toUpperCase()}
				</div>
			</>
		);
	})();

	return (
		<div
			className={'copy-56C9'}
			onClick={onClick}
		>
			<div className={'icon-1CE1'}>
				{successNode}
			</div>
			{previewNode}
		</div>
	);
};

export default Copy;