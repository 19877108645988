import React from 'react';
import {Build} from '../../../client/Build';
import BuildContentHeader from './BuildContentHeader';

interface BuildContentProps
{
	build: Build,
}

const BuildContent: React.FunctionComponent<BuildContentProps> = (props) =>
{
	return (
		<div>
			<BuildContentHeader build={props.build} />
		</div>
	);
};

export default BuildContent;