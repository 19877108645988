import React, {ReactNode, useState} from 'react';
import {Client} from '../../client/Client';

interface ClientContextProviderProps
{
	children?: ReactNode;
}

export const ClientContext = React.createContext<null | Client>(null);

const ClientContextProvider: React.FunctionComponent<ClientContextProviderProps> = (props) =>
{
	const [client] = useState<Client>(new Client());

	return (
		<ClientContext.Provider value={client}>
			{props.children}
		</ClientContext.Provider>
	);
};

export default ClientContextProvider;